import './styles.css'
import './styles-bbb.css'
import './styles-hr.css'

// import {Pane} from 'tweakpane';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { PixelShader } from 'three/examples/jsm/shaders/PixelShader.js'
import { SobelOperatorShader } from 'three/examples/jsm/shaders/SobelOperatorShader.js'
import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader.js'
import { AsciiEffect } from 'three/examples/jsm/effects/AsciiEffect.js'
import * as THREE from 'three'

const basePadding = 8;
const padding = 512 + (basePadding * 2) + basePadding;
var root, header, logo, pointy, transition, imgZoom, coolness, coolnessContainer, effectSwitcher, webring, mainUI;
var oloc = null;
var oimg = null;
var effectSwitchScale = 0.95;
const fridays = ['😿 Today is Sad Friday. 😿',
                 '🚫 Today is Anti Friday. 🚫',
                 '🐭 Today is Smol Friday. 🐭',
                 '🐪 Today is Hump Friday. 🐪',
                 '🎉 Today is Little Friday. 🎉',
                 'Today is Friday.',
                 '🤘 Today is Ultimate Friday. 🤘'];

window.onload=()=>{
    if (localStorage.getItem("naptimeyay-effect") == null) {
        localStorage.setItem("naptimeyay-effect", randomInteger(0,4))
    }
    parameters.effect = localStorage.getItem("naptimeyay-effect");
    
    root = document.documentElement;
    header = document.getElementsByClassName('about-header')[0];
    logo = document.getElementsByClassName('logo')[0];
    pointy = document.getElementById('pointy');
    transition = document.getElementsByClassName('transition')[0];
    webring = document.querySelector('div.webring');
    mainUI = document.querySelector('div.content-container');

    coolnessContainer = document.querySelector('div.webglcontainer');
    coolness = document.querySelector('canvas.webgl');
    if(coolness) { setupCoolness(); }

    setupImageZoom();
    if(location.href.includes('bbb') || location.href.includes('hr')) {
        imgZoom = document.getElementsByClassName('imgZoom')[0];
        if(location.href.includes('bbb')) { document.getElementById('close-bbb').addEventListener('click', handleSubNav); }
        else if(location.href.includes('hr')) { document.getElementById('close-hr').addEventListener('click', handleSubNav); }
    }
    else {
        document.getElementsByClassName('rando-gif')[0].src = 'resources/g' + randomInteger(0,5) + '.gif';
        effectSwitcher = document.getElementById('effect-switcher');
        effectSwitcher.addEventListener('click', switchEffect);
        effectSwitcher.addEventListener('mouseover', switchOver);
        effectSwitcher.addEventListener('mouseout', switchOut);
        document.getElementById('link-bbb').addEventListener('click', handleIndexNav);
        document.getElementById('link-hr').addEventListener('click', handleIndexNav);
        setSizes();
        setFriday();
    }
    if(location.href.includes('back')) { handleTransition(false); }
};

window.onresize=()=>{
    if(!location.href.includes('bbb') && !location.href.includes('hr')) { setSizes(); }
};

const setSizes=()=>{
    const s = window.innerWidth;
    if(s <= 850) { 
        const ls = s - 32;
        header.style.width = ls + 'px'; 
        const d = document.getElementById('foot').getBoundingClientRect().height;
        logo.style.height = d + 'px'; 
        logo.style.width = d + 'px'; 
        webring.style.width = (s - d - 32 * 2.5) + 'px';
        pointy.innerHTML = "👇";
        effectSwitchScale = 0.65;
    }
    else { 
        header.style.width = (s - padding) + 'px'; 
        logo.style.height = (512 - 32) + 'px'; 
        logo.style.width = (512 - 32) + 'px'; 
        webring.style.width = '100%';
        pointy.innerHTML = "👉";
        effectSwitchScale = 0.95;
    }
    if(pane){ document.body.appendChild( pane.containerElem_ ); }
};

const setFriday=()=>{
    const f = document.getElementById('friday');
    const d = new Date();
    f.innerHTML = fridays[d.getDay()];
};

const handleSubNav=(event)=>{
    event.preventDefault();
    const s = document.getElementsByClassName('stack');
    for(var i = 0; i < s.length; i++){
        s[i].classList.add('anim-stackOut');
    }
    setTimeout(function() { 
        var fileName = location.href.split("/").slice(-1)[0]; 
        history.pushState(null, null, fileName);
        window.location.replace(event.target.href); 
    }, 250);
};

const handleIndexNav=(event)=>{
    event.preventDefault();
    handleTransition(true);
    setTimeout(function() { 
        var fileName = location.href.split("/").slice(-1)[0]; 
        history.pushState(null, null, fileName);
        window.location.replace(event.target.href); 
    }, 400);
};

const handleTransition=(to)=>{
    transition.classList.remove('anim-navTo');
    transition.classList.remove('anim-navFrom');
    const r = logo.getBoundingClientRect();
    console.log('offset ' + window.pageYOffset)
    if(to){
        root.style.setProperty('--t-s-t', `${(r.top - basePadding + window.pageYOffset)}px`);
        root.style.setProperty('--t-s-l', `${(r.left - basePadding)}px`);
        root.style.setProperty('--t-s-h', `${r.height}px`);
        root.style.setProperty('--t-s-w', `${r.width}px`);
        root.style.setProperty('--t-e-t', '0');
        root.style.setProperty('--t-e-l', '0');
        root.style.setProperty('--t-e-h', `${window.innerHeight + window.pageYOffset}px`);
        root.style.setProperty('--t-e-w', `${window.innerWidth}px`);
        transition.style.display = 'block';
        transition.style.top = (r.top - basePadding + window.pageYOffset) + 'px';
        transition.style.left = (r.left - basePadding) + 'px';
        transition.style.height = r.height + 'px';
        transition.style.width = r.width + 'px';
        transition.classList.add('anim-navTo');
    }
    else {
        root.style.setProperty('--t-s-t', `${window.pageYOffset}px`);
        root.style.setProperty('--t-s-l', '0');
        root.style.setProperty('--t-s-h', `${window.innerHeight + window.pageYOffset}px`);
        root.style.setProperty('--t-S-w', `${window.innerWidth}px`);
        root.style.setProperty('--t-e-t', `${(r.top - basePadding + window.pageYOffset)}px`);
        root.style.setProperty('--t-e-l', `${(r.left - basePadding)}px`);
        root.style.setProperty('--t-e-h', `${r.height}px`);
        root.style.setProperty('--t-e-w', `${r.width}px`);
        transition.style.display = 'block';
        transition.style.opacity = '1';
        transition.style.top = '0px';
        transition.style.left = '0px';
        transition.style.height = window.innerHeight + window.pageYOffset + 'px';
        transition.style.width = window.innerWidth + 'px';
        transition.classList.add('anim-navFrom');
    }
};

const setupImageZoom=()=>{
    const imgs = document.getElementsByTagName('img');
    for(var i = 0; i < imgs.length; i++) { 
        if(!imgs[i].src.includes('svg') && !imgs[i].src.includes('gif')) { 
            imgs[i].addEventListener('click',zoomImage);  
            imgs[i].style.cursor = "url('resources/cursor-link.png'),auto";
        }
    }
};

const zoomImage=(event)=>{
    imgZoom.classList.remove('anim-imgZoomer');

    var tH, tW, cV, cH;
    if(window.innerWidth > window.innerHeight){
        tW = (window.innerHeight - basePadding * 2) * (event.target.width/event.target.height);
        cH = (window.innerWidth - tW) * 0.5;
        tH = window.innerHeight - basePadding * 2;
        cV = basePadding + window.pageYOffset;
    }
    else {
        tH = (window.innerWidth - basePadding * 2) * (event.target.height/event.target.width);
        cV = (window.innerHeight - tH) * 0.5 + window.pageYOffset;
        tW = window.innerWidth - basePadding * 2;
        cH = basePadding;
    }
    if(oloc) {
        root.style.setProperty('--t-s-t', `${cV}px`);
        root.style.setProperty('--t-s-l', `${cH}px`);
        root.style.setProperty('--t-s-h', `${tH}px`);
        root.style.setProperty('--t-s-w', `${tW}px`);
        root.style.setProperty('--t-e-t', `${(oloc.top+window.pageYOffset)}px`);
        root.style.setProperty('--t-e-l', `${(oloc.left)}px`);
        root.style.setProperty('--t-e-h', `${oloc.height}px`);
        root.style.setProperty('--t-e-w', `${oloc.width}px`);
        setTimeout(function() { 
            oimg.style.visibility = 'visible';
            imgZoom.style.visibility = 'hidden';
            imgZoom.src = '';
            oloc = null;
            oimg = null;
            document.body.classList.remove('stop-scrolling');
        }, 400);
        imgZoom.classList.add('anim-imgZoomer');
    }
    else {
        imgZoom.onload = ()=>{
            const cloc = event.target.getBoundingClientRect();
            imgZoom.style.visibility = 'visible';
            imgZoom.style.top = cloc.top + 'px';
            imgZoom.style.left = cloc.left + 'px';
            imgZoom.style.height = cloc.height + 'px';
            imgZoom.style.width = cloc.width + 'px';
            root.style.setProperty('--t-s-t', `${(cloc.top+window.pageYOffset)}px`);
            root.style.setProperty('--t-s-l', `${(cloc.left)}px`);
            root.style.setProperty('--t-s-h', `${(cloc.height)}px`);
            root.style.setProperty('--t-s-w', `${(cloc.width)}px`);
            root.style.setProperty('--t-e-t', `${cV}px`);
            root.style.setProperty('--t-e-l', `${cH}px`);
            root.style.setProperty('--t-e-h', `${tH}px`);
            root.style.setProperty('--t-e-w', `${tW}px`);
            document.body.classList.add('stop-scrolling');
            oloc = cloc;
            oimg = event.target;
            setTimeout(function() { 
                oimg.style.visibility = 'hidden';
            }, 100);
            imgZoom.classList.add('anim-imgZoomer');
        }
        imgZoom.src = event.target.src;
    }
};

// Helper Funcs
const randomInteger=(min, max)=>{
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
// THREEEEEEEEEEEEE ///////////////////////////JS//////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
const mouse = new THREE.Vector2()
let mouseAngle = 0;

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

const parameters = {
   effect: 0,
   ascii: false,
   asciiForegroundColor: '#DDDDDD',
   asciiBackgroundColor: '#111111',
   bloom: false,
   pixel: false,
   pixelSize: 8,
   sobel: false,
   bloom: false,
   bloomStrength: 0.3,
   bloomRadius: 1,
   bloomThreshold: 0.6,
   rgbShift: false,
   rgbShiftAmount: 0.005,
   rgbShiftAngle: 0,
   kSides: 1,
   kDuplicates: 1,
   kAngle: 0,
   kSpeed: 0.2,
   kOffset: 0,
   kMode: 0,
   kColor1: '#FFFFFF',
   kColor2: '#FFFFFF',
   kImage: '0',
   kAlpha: false,
   sceneBackgroundColor: '#111111',
   postprocessing: false
 }

 let pane;
// effect tweaker
// pane = new Pane({
//     title: 'Tweaks',
//     expanded: true
// })
// const kaleidoShapeFolder = pane.addFolder({
//     title: 'Kaleido Settings',
//     expanded: true,
// })
// kaleidoShapeFolder.addInput(parameters, 'kImage', {
//     label: 'image',
//     options: {
//       me: '0',
//       logo: '1',
//       lavender: '2',
//       water: '3',
//       trees: '4'
//     },
// }).on('change', () =>{
//     build()
// })  
// kaleidoShapeFolder.addInput(parameters, 'kAlpha', {label: 'Use alpha'}).on('change', () => {
//     kColor1Blade.hidden = !parameters.kAlpha
//     kColor2Blade.hidden = kColor1Blade.hidden
//     kaleidoShape.material.uniforms.uAlpha.value = (parameters.kAlpha ? 1 : 0)
// })
// kaleidoShapeFolder.addInput(parameters, 'kMode', {
//     label: 'mode',
//     options: {
//       standard: 0,
//       cool: 1,
//       also_cool: 2
//     },
// }).on('change', () =>{kaleidoShape.material.uniforms.uMode.value = parameters.kMode})  
// kaleidoShapeFolder.addInput(parameters, 'kDuplicates', {label: 'duplicates', step: 1, min: 1, max: 25}).on('change', () =>{ kaleidoShape.material.uniforms.uDuplicates.value = parameters.kDuplicates })
// kaleidoShapeFolder.addInput(parameters, 'kSides', {label: 'reflections', step: 0.001, min: 1, max: 25}).on('change', () =>{ kaleidoShape.material.uniforms.uSides.value = parameters.kSides })
// kaleidoShapeFolder.addInput(parameters, 'kAngle', {label: 'angle', step: 0.001, min: -25, max: 25}).on('change', () =>{ kaleidoShape.material.uniforms.uAngle.value = parameters.kAngle })
// kaleidoShapeFolder.addInput(parameters, 'kSpeed', {label: 'speed', step: 0.001, min: 0, max: 5}).on('change', () =>{ kaleidoShape.material.uniforms.uSpeed.value = parameters.kSpeed })
// kaleidoShapeFolder.addInput(parameters, 'kOffset', {label: 'offset', step: 0.001, min: -1, max: 1}).on('change', () =>{ kaleidoShape.material.uniforms.uOffset.value = parameters.kOffset })
// const kColor1Blade = kaleidoShapeFolder.addInput(parameters, 'kColor1', {label: 'color 1', view: 'color' }).on('change', () =>{ kaleidoShape.material.uniforms.uColor1.value = new THREE.Color(parameters.kColor1) })
// const kColor2Blade = kaleidoShapeFolder.addInput(parameters, 'kColor2', {label: 'color 2', view: 'color' }).on('change', () =>{ kaleidoShape.material.uniforms.uColor2.value = new THREE.Color(parameters.kColor2) })
// f = pane.addFolder({
//     title: 'Effects',
//     expanded: true,
// })
// const asciiBlade = f.addInput(parameters, 'ascii').on('change', () =>{
//     if(parameters.ascii) {
//         addAscii();
//         asciiFCBlade.hidden = false
//         asciiBGBlade.hidden = false
//         pixelBlade.hidden = true  
//         sobelBlade.hidden = true 
//         bloomBlade.hidden = true
//         shiftBlade.hidden = true 
//     }
//     else {
//         removeAscii();
//         asciiFCBlade.hidden = true 
//         asciiBGBlade.hidden = true 
//         pixelBlade.hidden = false  
//         sobelBlade.hidden = false 
//         bloomBlade.hidden = false 
//         shiftBlade.hidden = false 
//     }
//     pane.refresh()
// })
// const asciiFCBlade = f.addInput(parameters, 'asciiForegroundColor', {label: 'ascii color', view: 'color' }).on('change', () =>{asciiEffect.domElement.style.color = parameters.asciiForegroundColor })
// asciiFCBlade.hidden = true
// const asciiBGBlade = f.addInput(parameters, 'asciiBackgroundColor', {label: 'ascii bg color', view: 'color' }).on('change', () =>{ asciiEffect.domElement.style.backgroundColor = parameters.asciiBackgroundColor })
// asciiBGBlade.hidden = true
// const pixelBlade = f.addInput(parameters, 'pixel').on('change', () =>{
//     if(parameters.pixel) {
//         pixelEffect = new ShaderPass(PixelShader)
//         pixelEffect.uniforms.resolution.value = new THREE.Vector2(sizes.width,sizes.height)
//         pixelEffect.uniforms.resolution.value.multiplyScalar(window.devicePixelRatio)
//         pixelEffect.uniforms.pixelSize.value = parameters.pixelSize
//         removePostProcessing()
//         addPostProcessing()
//         parameters.postprocessing = true
//         pSizeBlade.hidden = false
//         asciiBlade.hidden = true 
//         asciiFCBlade.hidden = true 
//         asciiBGBlade.hidden = true 
//     }
//     else {
//         composer.removePass(pixelEffect)
//         pixelEffect = null
//         if (!sobelEffect && !bloomEffect && !rgbShiftEffect) {
//             parameters.postprocessing = false 
//             asciiBlade.hidden = false 
//         }
//         pSizeBlade.hidden = true
//     }
//     pane.refresh()
// })
// const pSizeBlade = f.addInput(parameters, 'pixelSize', {step: 2, min: 4, max: 64}).on('change', () => {pixelEffect.uniforms.pixelSize.value = parameters.pixelSize})
// pSizeBlade.hidden = true
// const sobelBlade = f.addInput(parameters, 'sobel').on('change', () =>{
//     if(parameters.sobel) {
//         sobelEffect = new ShaderPass(SobelOperatorShader)
//         sobelEffect.uniforms.resolution.value = new THREE.Vector2(sizes.width,sizes.height)
//         removePostProcessing()
//         addPostProcessing()
//         parameters.postprocessing = true 
//         asciiBlade.hidden = true 
//         asciiFCBlade.hidden = true 
//         asciiBGBlade.hidden = true  
//     }
//     else {
//         composer.removePass(sobelEffect)
//         sobelEffect = null
//         if (!pixelEffect && !bloomEffect && !rgbShiftEffect) {
//             parameters.postprocessing = false 
//             asciiBlade.hidden = false 
//         }
//     }
//     pane.refresh()
// })
// const bloomBlade = f.addInput(parameters, 'bloom').on('change', () =>{
//     if(parameters.bloom) {
//         bloomEffect = new UnrealBloomPass()
//         bloomEffect.strength = parameters.bloomStrength
//         bloomEffect.radius = parameters.bloomRadius
//         bloomEffect.threshold = parameters.bloomThreshold
//         removePostProcessing()
//         addPostProcessing()
//         parameters.postprocessing = true 
//         asciiBlade.hidden = true 
//         asciiFCBlade.hidden = true 
//         asciiBGBlade.hidden = true  
//         bStrengthBlade.hidden = false 
//         bRadiusBlade.hidden = false 
//         bThreshBlade.hidden = false 
//     }
//     else {
//         composer.removePass(bloomEffect)
//         bloomEffect = null
//         bStrengthBlade.hidden = true 
//         bRadiusBlade.hidden = true 
//         bThreshBlade.hidden = true 
//         if (!pixelEffect && !sobelEffect && !rgbShiftEffect) {
//             parameters.postprocessing = false 
//             asciiBlade.hidden = false 
//         }
//     }
//     pane.refresh()
// })
// const bStrengthBlade = f.addInput(parameters, 'bloomStrength', {label: 'strength', step: 0.001, min: 0, max: 2}).on('change', () => {bloomEffect.strength = parameters.bloomStrength})
// bStrengthBlade.hidden = true
// const bRadiusBlade = f.addInput(parameters, 'bloomRadius', {label: 'radius', step: 0.001, min: 0, max: 2}).on('change', () => {bloomEffect.radius = parameters.bloomRadius})
// bRadiusBlade.hidden = true 
// const bThreshBlade = f.addInput(parameters, 'bloomThreshold', {label: 'threshold', step: 0.001, min: 0, max: 1}).on('change', () => {bloomEffect.threshold = parameters.bloomThreshold})
// bThreshBlade.hidden = true 
// const shiftBlade = f.addInput(parameters, 'rgbShift').on('change', () =>{
//     if(parameters.rgbShift) {
//         rgbShiftEffect = new ShaderPass(RGBShiftShader)
//         removePostProcessing()
//         addPostProcessing()
//         parameters.postprocessing = true 
//         asciiBlade.hidden = true 
//         asciiFCBlade.hidden = true 
//         asciiBGBlade.hidden = true  
//         rgbStrengthBlade.hidden = false
//         rgbAngleBlade.hidden = false 
//     }
//     else {
//         composer.removePass(rgbShiftEffect)
//         rgbShiftEffect = null
//         rgbStrengthBlade.hidden = true
//         rgbAngleBlade.hidden = true 
//         if (!pixelEffect && !sobelEffect && !bloomEffect) {
//             parameters.postprocessing = false 
//             asciiBlade.hidden = false 
//         }
//     }
//     pane.refresh()
// })
// const rgbStrengthBlade = f.addInput(parameters, 'rgbShiftAmount', {label: 'amount', step: 0.001, min: 0, max: 1}).on('change', () => {rgbShiftEffect.uniforms.amount.value = parameters.rgbShiftAmount})
// rgbStrengthBlade.hidden = true
// const rgbAngleBlade = f.addInput(parameters, 'rgbShiftAngle', {label: 'angle',step: 0.001, min: 0, max: Math.PI * 2}).on('change', () => {rgbShiftEffect.uniforms.angle.value = parameters.rgbShiftAngle})
// rgbAngleBlade.hidden = true 

/**
 * Scene builders
 */
 const setup = () => {
    teardown();
    switch(parseInt(parameters.effect)){
        case 0:
            build = buildEffect1;
            handleMouse = mouseEffect1;
            break
        case 1:
            build = buildEffect2;
            handleMouse = mouseEffect2;
            break
        case 2:
            build = buildEffect3;
            handleMouse = mouseEffect3;
            break
        case 3:
            build = buildEffect4;
            handleMouse = mouseEffect4;
            break
        case 4:
            build = buildEffect5;
            handleMouse = mouseEffect5;
            break
    }
    build();
}

const teardown=()=>{
    removeAscii();
    removePostProcessing();
    teardownKaleido();
}

const switchEffect=()=>{
    parameters.effect++;
    if(parameters.effect > 4){parameters.effect = 0;}
    localStorage.setItem("naptimeyay-effect", parameters.effect);
    setup();
    gsap.fromTo(effectSwitcher, {scale: effectSwitchScale}, {scale:1, duration:1.0, ease: 'elastic(1, 0.2)'});
}

const switchOver=()=>{
    gsap.to(effectSwitcher, {scale:1.025, duration:0.5, ease: 'elastic(1, 0.4)'});
}

const switchOut=()=>{
    gsap.to(effectSwitcher, {scale:1, duration:0.5, ease: 'elastic(1, 0.2)'});
}

const buildEffect1=()=>{
    parameters.ascii = true;
    parameters.postprocessing = false;
    parameters.kImage = 0;
    parameters.kAlpha = true;
    parameters.kDuplicates = 2;
    parameters.kSides = 1;
    parameters.kAngle = -10;
    parameters.kSpeed = 0.025;
    parameters.kOffset = 0;
    parameters.kMode = 0;
    parameters.kColor1 = '#EAE1B1';
    parameters.kColor2 = '#0D2344';
    parameters.asciiForegroundColor = '#81d5af';
    parameters.asciiBackgroundColor = '#0b2b34';
    addAscii();
    buildKaleido();
}

const mouseEffect1=()=>{
    asciiEffect.domElement.style.color = gsap.utils.interpolate(parameters.asciiForegroundColor, parameters.kColor1, mouse.x);
    asciiEffect.domElement.style.backgroundColor = gsap.utils.interpolate(parameters.asciiBackgroundColor, parameters.kColor2, mouse.y);
    if(!kaleidoShape){ return; }
    gsap.to(kaleidoShape.material.uniforms.uOffset, {value: parameters.kOffset + (mouse.x * 0.5 - 0.25), ease: "power2.inout",duration: 4})
}

const buildEffect2=()=>{
    parameters.ascii = false;
    parameters.postprocessing = true;
    parameters.kImage = 1;
    parameters.kAlpha = false;
    parameters.kDuplicates = 2;
    parameters.kSides = 1.609;
    parameters.kAngle = 1.087;
    parameters.kSpeed = 0.109;
    parameters.kOffset = 0.587;
    parameters.kMode = 2;
    parameters.pixelSize = 62;
    parameters.bloomStrength = 2.0;
    parameters.bloomRadius = 0.565;
    parameters.bloomThreshold = 0.826;
    parameters.rgbShiftAmount = 0.005;
    parameters.rgbShiftAngle = 0.751;

    pixelEffect = new ShaderPass(PixelShader);
    pixelEffect.uniforms.resolution.value = new THREE.Vector2(sizes.width,sizes.height);
    pixelEffect.uniforms.resolution.value.multiplyScalar(window.devicePixelRatio);
    pixelEffect.uniforms.pixelSize.value = parameters.pixelSize;

    sobelEffect = new ShaderPass(SobelOperatorShader);
    sobelEffect.uniforms.resolution.value = new THREE.Vector2(sizes.width,sizes.height);

    bloomEffect = new UnrealBloomPass();
    bloomEffect.strength = parameters.bloomStrength;
    bloomEffect.radius = parameters.bloomRadius;
    bloomEffect.threshold = parameters.bloomThreshold;

    rgbShiftEffect = new ShaderPass(RGBShiftShader);
    rgbShiftEffect.uniforms.amount.value = parameters.rgbShiftAmount;
    rgbShiftEffect.uniforms.angle.value = parameters.rgbShiftAngle;

    addPostProcessing();
    buildKaleido();
}

const mouseEffect2=()=>{
    if(!pixelEffect || !kaleidoShape){ return; }
    gsap.to(pixelEffect.uniforms.pixelSize, {value: parameters.pixelSize - (mouse.x * 40), ease: "power2.inout",duration: 1.25})
    gsap.to(kaleidoShape.material.uniforms.uSpeed, {value: parameters.kSpeed + (mouse.y * 0.05), ease: "power2.inout",duration: 1.25})
}

const buildEffect3=()=>{
    parameters.ascii = false;
    parameters.postprocessing = true;
    parameters.kImage = 0;
    parameters.kAlpha = false;
    parameters.kDuplicates = 6;
    parameters.kSides = -15;
    parameters.kAngle = 2;
    parameters.kSpeed = 0.05;
    parameters.kOffset = 1.0;
    parameters.kMode = 1;
    parameters.pixelSize = 2;
    parameters.bloomStrength = 0.25;
    parameters.bloomRadius = 0.05;
    parameters.bloomThreshold = 0.75;
    parameters.rgbShiftAmount = 0.005;
    parameters.rgbShiftAngle = 0.751;

    pixelEffect = new ShaderPass(PixelShader);
    pixelEffect.uniforms.resolution.value = new THREE.Vector2(sizes.width,sizes.height);
    pixelEffect.uniforms.resolution.value.multiplyScalar(window.devicePixelRatio);
    pixelEffect.uniforms.pixelSize.value = parameters.pixelSize;

    sobelEffect = new ShaderPass(SobelOperatorShader);
    sobelEffect.uniforms.resolution.value = new THREE.Vector2(sizes.width,sizes.height);

    bloomEffect = new UnrealBloomPass();
    bloomEffect.strength = parameters.bloomStrength;
    bloomEffect.radius = parameters.bloomRadius;
    bloomEffect.threshold = parameters.bloomThreshold;

    rgbShiftEffect = new ShaderPass(RGBShiftShader);
    rgbShiftEffect.uniforms.amount.value = parameters.rgbShiftAmount;
    rgbShiftEffect.uniforms.angle.value = parameters.rgbShiftAngle;

    addPostProcessing();
    buildKaleido();
}

const mouseEffect3=()=>{
    if(!kaleidoShape){ return; }
    gsap.to(kaleidoShape.material.uniforms.uAngle, {value: parameters.kAngle + (mouse.x * 1), ease: "power2.inout",duration: 3})
    gsap.to(kaleidoShape.material.uniforms.uOffset, {value: parameters.kOffset - (mouse.x * 0.5), ease: "power2.inout",duration: 4})
    gsap.to(kaleidoShape.material.uniforms.uSides, {value: parameters.kSides + (mouse.y * 10), ease: "power2.inout",duration: 3})
}

const buildEffect4=()=>{
    parameters.ascii = false;
    parameters.postprocessing = false;
    parameters.kImage = 4;
    parameters.kAlpha = false;
    parameters.kDuplicates = 1;
    parameters.kSides = 2.087;
    parameters.kAngle = -14.130;
    parameters.kSpeed = 0.054;
    parameters.kOffset = 0;
    parameters.kMode = 2;
    buildKaleido();
}

const mouseEffect4=()=>{
    if(!kaleidoShape){ return; }
    gsap.to(kaleidoShape.material.uniforms.uSides, {value: parameters.kSides - (mouse.x * 0.1), ease: "power2.inout",duration: 3})
    gsap.to(kaleidoShape.material.uniforms.uAngle, {value: parameters.kAngle - (mouse.y * 0.2), ease: "power2.inout",duration: 4})
}

const buildEffect5=()=>{
    parameters.ascii = false;
    parameters.postprocessing = true;
    parameters.kImage = 1;
    parameters.kAlpha = false;
    parameters.kDuplicates = 2;
    parameters.kSides = 1;
    parameters.kAngle = 0.543;
    parameters.kSpeed = 0.054;
    parameters.kOffset = 0;
    parameters.kMode = 1;
    parameters.pixelSize = 1;
    parameters.rgbShiftAmount = 0.25;
    parameters.rgbShiftAngle = 1.912;

    pixelEffect = new ShaderPass(PixelShader);
    pixelEffect.uniforms.resolution.value = new THREE.Vector2(sizes.width,sizes.height);
    pixelEffect.uniforms.resolution.value.multiplyScalar(window.devicePixelRatio);
    pixelEffect.uniforms.pixelSize.value = parameters.pixelSize;
    
    rgbShiftEffect = new ShaderPass(RGBShiftShader);
    rgbShiftEffect.uniforms.amount.value = parameters.rgbShiftAmount;
    rgbShiftEffect.uniforms.angle.value = parameters.rgbShiftAngle;

    addPostProcessing();
    buildKaleido();
}

const mouseEffect5=()=>{
    if(!kaleidoShape){ return; }
    parameters.rgbShiftAngle = mouseAngle;
    gsap.to(kaleidoShape.material.uniforms.uOffset, {value: parameters.kOffset - (mouse.x * 0.25), ease: "power2.inout",duration: 3})
    gsap.to(rgbShiftEffect.uniforms.angle, {value: parameters.rgbShiftAngle, ease: "power2.inout",duration: 5})
}

const addPostProcessing = () => {
    if(pixelEffect) { composer.addPass(pixelEffect) } 
    if(sobelEffect) { composer.addPass(sobelEffect) } 
    if(bloomEffect) { composer.addPass(bloomEffect) } 
    if(rgbShiftEffect) { composer.addPass(rgbShiftEffect) } 
}

const removePostProcessing = () => {
    if(pixelEffect) { composer.removePass(pixelEffect) } 
    if(sobelEffect) { composer.removePass(sobelEffect) } 
    if(bloomEffect) { composer.removePass(bloomEffect) } 
    if(rgbShiftEffect) { composer.removePass(rgbShiftEffect) } 
}

const addAscii = () => {
    asciiEffect = new AsciiEffect( renderer, ' .-+*#$@', { invert: true } )
    asciiEffect.setSize( sizes.width, sizes.height )
    asciiEffect.domElement.style.color = parameters.asciiForegroundColor
    asciiEffect.domElement.style.backgroundColor = parameters.asciiBackgroundColor
    renderer.domElement.remove()
    coolnessContainer.appendChild( asciiEffect.domElement )
}

const removeAscii = () => {
    if(!asciiEffect){ return }
    coolnessContainer.appendChild( renderer.domElement )
    if(pane) { document.body.appendChild( pane.containerElem_ ) }
    asciiEffect.domElement.remove()
    asciiEffect = null
}

const clock = new THREE.Clock()
const textureLoader = new THREE.TextureLoader()
let scene, camera, renderer, composer, build, handleMouse, asciiEffect, pixelEffect, sobelEffect, bloomEffect, kaleidoShape, kaleidoTexture, rgbShiftEffect
const buildKaleido = () => {
    textureLoader.load('resources/texture-' + parameters.kImage + '.jpg',
        function(t) {
            kaleidoTexture = t
            if(kaleidoShape) {
                kaleidoShape.material.uniforms.uTexture.value = kaleidoTexture
                return 
            }
            const geom = new THREE.PlaneGeometry(2, 2, 1, 1)
            const mat = new THREE.ShaderMaterial({
                uniforms: {
                    uTexture: { value: kaleidoTexture },
                    uSides: { value: parameters.kSides },
                    uDuplicates: {value: parameters.kDuplicates},
                    uAngle: { value: parameters.kAngle },
                    uTime: {value: 0 },
                    uSpeed: {value: parameters.kSpeed },
                    uOffset: {value: parameters.kOffset },
                    uMode: {value: parameters.kMode },
                    uColor1: {value: new THREE.Color(parameters.kColor1) },
                    uColor2: {value: new THREE.Color(parameters.kColor2) },
                    uAlpha: {value: (parameters.kAlpha ? 1 : 0)}
                  },
                transparent: true,
                vertexShader: /*glsl*/`
                    varying vec2 vUv;
                    void main()
                    {
                        gl_Position = vec4(position, 1.0);
                        vUv = uv;
                    }
                `,
                fragmentShader: /*glsl*/`
                    uniform sampler2D uTexture;
                    uniform float uSides;
                    uniform float uAngle;
                    uniform float uTime;
                    uniform float uSpeed;
                    uniform float uOffset;
                    uniform float uDuplicates;
                    uniform float uMode;
                    uniform vec3 uColor1;
                    uniform vec3 uColor2;
                    uniform float uAlpha;

                    varying vec2 vUv;

                    #define PI 3.1415926535897932384626433832795

                    float random(vec2 st) {
                        return fract(sin(dot(st.xy, vec2(12.9898,78.233)))* 43758.5453123);
                    }

                    vec2 preK(vec2 p) {
                        if(p.x > 0.5) p.x = 1.0 - p.x;
                        if(p.y > 0.5) p.y = 1.0 - p.y;
                        return p;
                    }

                    vec2 postK(vec2 p) {
                        p *= uDuplicates;
                        p = fract(p);
                        return  p + uOffset;
                    }

                    vec2 k1(vec2 p) {
                        p = preK(p);

                        p = p - uOffset;
                        float r = length(p);
                        float a = atan(p.y, p.x) + uAngle;
                        float tau = PI  + uTime * uSpeed;
                        a = mod(a, tau/uSides);
                        a = abs(a - tau/uSides/2.);
                        p = r * vec2(cos(a), sin(a));

                        return postK(p);
                    }

                    vec2 k2(vec2 p) {
                        p = preK(p);

                        p = p - uOffset;
                        float r = length(p) + sin(uTime * uSpeed);
                        float a = atan(p.y, p.x) + uAngle;
                        float tau = PI  + uTime * uSpeed;
                        a = mod(a, tau/uSides);
                        a = abs(a - tau/uSides/2.) ;
                        p = r * vec2(cos(a), sin(a));

                        return postK(p);
                    }
            
                    vec2 k3(vec2 p) {
                        p = preK(p);

                        p = p - uOffset;
                        float r = length(p);
                        float a = atan(p.y, p.x) + uAngle;
                        float tau = PI + uSpeed;
                        a = mod(a, tau/uSides);
                        a = abs(a - tau/uSides/2.) ;
                        p = r * vec2(cos(a+uTime * uSpeed), sin(a+uTime * uSpeed));

                        return postK(p);
                    }

                    vec4 ak(vec2 p) {
                        vec4 color = vec4(mix(uColor1, uColor2, p.y), 1.0);
                        color.a *= texture2D(uTexture, p).g;
                        return color;
                    }

                    vec4 rk(vec2 p) {
                        return texture2D(uTexture, p);
                    }

                    vec2 rotate2D (vec2 _st, float _angle) {
                        _st -= 0.5;
                        _st =  mat2(cos(_angle),-sin(_angle),
                                    sin(_angle),cos(_angle)) * _st;
                        _st += 0.5;
                        return _st;
                    }

                    void main() {
                        vec2 p = vUv;
                        p = (uMode == 0.0 ? k1(p) : (uMode == 1.0 ? k2(p) : k3(p)));
                        // p = rotate2D(p, uTime * uSpeed);

                        //staggered time offset
                        // if(p.x > 0.5) p.x = 1.0 - p.x;
                        // if(p.y > 0.5) p.y = 1.0 - p.y;
                        // p = p - uOffset;
                        // float r = length(p);
                        // float a = atan(p.y, p.x) + uTime * uSpeed;
                        // float tau = PI * uAngle;
                        // a = mod(a, tau/uSides);
                        // a = abs(a - tau/uSides/2.) ;
                        // p = r * vec2(cos(a+uTime * uSpeed), sin(a+uTime * uSpeed));

                        gl_FragColor = (uAlpha == 0.0 ? rk(p) : ak(p));
                    }
                `
            })
            kaleidoShape = new THREE.Mesh(geom, mat)
            kaleidoShape.position.z = -100
            scene.add(kaleidoShape)
    })
}

const tickKaleido = () => {
    if(!kaleidoShape){return}
    const elapsedTime = clock.getElapsedTime()
    kaleidoShape.material.uniforms.uTime.value = elapsedTime
}

const teardownKaleido = () => {
    if(!kaleidoShape) {return}
    scene.remove(kaleidoShape)
    kaleidoShape = null
    kaleidoTexture.dispose()
    kaleidoTexture = null 
}

const tick = () =>
{
    tickKaleido()
    if(parameters.ascii) { 
        asciiEffect.render(scene, camera) 
    }
    else if (parameters.postprocessing) {
        composer.render()
    }
    else {
        renderer.render(scene, camera)
    }
    window.requestAnimationFrame(tick)
}

const setupCoolness=()=>{
    /**
     * Setup
     */
    const canvas = coolness

    scene = new THREE.Scene()
    scene.background = new THREE.Color(parameters.sceneBackgroundColor)

    raycaster = new THREE.Raycaster()
    var plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), -10);
    var pointOfIntersection = new THREE.Vector3();

    camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 1, 1000)
    camera.position.z = 100
    scene.add(camera)

    renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true 
    })
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    coolnessContainer.appendChild( renderer.domElement )

    composer = new EffectComposer(renderer)
    composer.addPass(new RenderPass(scene, camera))
    const gammaCorrectionPass = new ShaderPass(GammaCorrectionShader)
    composer.addPass(gammaCorrectionPass)

    // Events
    window.addEventListener('resize', () =>
    {
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        if(asciiEffect) { asciiEffect.setSize(sizes.width, sizes.height) }
        if(pixelEffect) {
            pixelEffect.uniforms.resolution.value = new THREE.Vector2(sizes.width,sizes.height)
            pixelEffect.uniforms.resolution.value.multiplyScalar(window.devicePixelRatio)
        }
        if(sobelEffect) { sobelEffect.uniforms.resolution.value = new THREE.Vector2(sizes.width,sizes.height) }
    })

    window.addEventListener('pointermove', (e) => {
        mouse.x = ( e.clientX / window.innerWidth )
        mouse.y = ( e.clientY / window.innerHeight )

        const w = window.innerWidth/2;
        const h = window.innerHeight/2;
        const dx = w - e.clientX;
        const dy = h - e.clientY;
        mouseAngle = Math.atan2(dx, dy);

        if(handleMouse) {handleMouse()}
    })
    window.addEventListener('pointerdown', (e) => {
        if(e.target.classList.contains('main')) {
            document.body.style.overflow = 'hidden';
            gsap.to(mainUI, {opacity:0, duration:0.35});
        }
    })
    window.addEventListener('pointerup', (e) => {
        document.body.style.overflow = 'scroll';
        gsap.to(mainUI, {opacity:1, duration:0.35});
    })
    setup()
    tick()
}